@font-face {
  font-family: 'Prompt-Regular';
  src: url('assets/fonts/Prompt-Regular.ttf');
}

@font-face {
  font-family: 'Prompt-Bold';
  src: url('assets/fonts/Prompt-Bold.ttf');
}

@font-face {
  font-family: 'Prompt-Medium';
  src: url('assets/fonts/Prompt-Medium.ttf');
}


.title {
  font-family: 'Prompt-Regular';
}

* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  font-weight: 400;
  font-family: 'Prompt-Regular';
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: 'Prompt-Regular';
}

.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-tbody>tr>td,
.ant-table.ant-table-small .ant-table-thead>tr>th,
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small tfoot>tr>td,
.ant-table.ant-table-small tfoot>tr>th {
  padding: 4px;
}