.announce {
    width: 100%;
}
.announce tr td { 
    border: 1px solid #d9d9d9;
    padding: 3px;
}
.announce tr th {
    border: 1px solid #d9d9d9;
    padding: 3px;
}
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}