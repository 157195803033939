.ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
}

.inactive-bill {
    background-color: #FF9999;
}

.price, .win {
    color: #389e0d;
    font-weight: bold;
}

.commission, .price-pay, .lose {
    color: #cf1322;
    font-weight: bold;
}

.total, .net {
    color: #2db7f5;
    font-weight: bold;
}

.bet-report {
    background-color: black;
    color: white;
}

.ant-table-summary {
    background: #fafafa;
    transition: background .3s ease;
}