.sider {
    padding: 20px 5px 5px 5px;
    /* height: 100px; */
    text-align: center;
    color: #fff;
    font-size: 16px;
}

.content {
    padding: 22px;
    /* margin: 20px; */
    background: #fff;
    min-height: 280px;
    border-radius: 5px;
}

.layout {
    min-height: 100vh;
}

.header {
    background: #fff;
    padding: 0px;
}

.user-detail {
    padding: 10px;
    color: #fff;
}

.ad {
    padding: 10px;
    color: #fff;
}

.ant-descriptions-row>td {
    padding-bottom: 0px;
}

.contact {
    position: absolute;
    bottom: 10px;
    padding: 10px;
    color: #fff;
}

.ant-drawer-body {
    background-color: #1e1e2d;
}

.ant-drawer-header {
    background-color: #1e1e2d;
}